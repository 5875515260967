import { IAppState } from './state'
import { useCallback, useContext } from 'react'
import { AppContext } from '.'
import { AppEnumType } from './actions'

export const useApp = () => {
  const { isMobile, isTablet, dispatch } = useContext(AppContext)

  const setIsMobile = useCallback(
    (state: IAppState) => dispatch(AppEnumType.SET_IS_MOBILE, state),
    [dispatch],
  )
  const setIsTablet = useCallback(
    (state: IAppState) => dispatch(AppEnumType.SET_IS_TABLET, state),
    [dispatch],
  )

  return {
    isMobile,
    isTablet,
    setIsMobile,
    setIsTablet,
  }
}
