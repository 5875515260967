import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client'

const mainEndpoint = createHttpLink({
  uri: process.env.REACT_APP_API_GRAPHQL,
})

export const client = new ApolloClient({
  link: from([mainEndpoint]),
  cache: new InMemoryCache(),
})
