import { useApp } from 'contexts/app-context/use-app'
import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BsArrowRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import {
  Flex,
  Image,
  Relative,
  Section,
  Space,
  Text,
  Wrapper,
} from 'styles/styled-components'
import {
  DARK_BLUE_COLOR,
  EXTRA_BOLD_FONT_WEIGHT,
  HEADING_2_FONT_SIZE,
  HEADING_3_FONT_SIZE,
  HEADING_4_FONT_SIZE,
  LIGHT_BG_GRAY_COLOR,
  LIGHT_GRAY_COLOR,
  MEDIUM_FONT_WEIGHT,
  MEDIUM_GRAY_COLOR,
  MOB_HEADING_2_FONT_SIZE,
  MOB_HEADING_4_FONT_SIZE,
  MOB_TEXT_FONT_SIZE,
  PRIMARY_COLOR,
  REGULAR_FONT_WEIGHT,
  SECONDARY_COLOR,
  SEMI_BOLD_FONT_WEIGHT,
  TEXT_FONT_SIZE,
} from 'styles/styled-components/variables'
import Constants from '../../../../constants'

const Features: FunctionComponent = () => {
  const { isMobile, isTablet } = useApp()

  const appFeatureDescription = [
    {
      title: 'Geolocation Confirmation',
      description: 'Confirm your team’s location in real time.',
    },
    {
      title: 'AI Auto-Assigment by Area',
      description:
        'Our AI is built to set assignment for your sales agents to leads in specific areas. Automatically.',
    },
    {
      title: 'Route Optimization',
      description: 'Enable the fastest route for your sales teams.',
    },
  ]

  const webFeatureDescription = [
    {
      title: 'Fraud Detection & Analytics',
      description:
        'Something looks odd? Detect and flag it with your own fraud rules.',
      image: `${Constants.general.PUBLIC_URL}/assets/images/fraud-analytics-feature.png`,
    },
    {
      title: 'Customer & Team History',
      description:
        'Track every detail of your customers’ & sales teams’ activities.',
      image: `${Constants.general.PUBLIC_URL}/assets/images/customer-team-history-feature.png`,
    },
    {
      title: 'Submission Approval',
      description:
        'Import back all files & update all submissions. In an instant.',
      image: `${Constants.general.PUBLIC_URL}/assets/images/submission-approval-feature.png`,
    },
    {
      title: 'Custom Survey',
      description: 'Easily create a unique survey for every field team.',
      image: `${Constants.general.PUBLIC_URL}/assets/images/custom-survey-feature.png`,
    },
  ]

  const renderAppFeatureDescription = () => {
    const content = appFeatureDescription.map((item, idx) => (
      <Wrapper key={idx}>
        <Text
          color={DARK_BLUE_COLOR}
          fontSize={
            isMobile || isTablet ? MOB_HEADING_2_FONT_SIZE : HEADING_3_FONT_SIZE
          }
          fontWeight={SEMI_BOLD_FONT_WEIGHT}
        >
          {item.title}
        </Text>
        <Text
          color={MEDIUM_GRAY_COLOR}
          fontSize={TEXT_FONT_SIZE}
          fontWeight={REGULAR_FONT_WEIGHT}
          maxWidth={'460px'}
        >
          {item.description}
        </Text>
      </Wrapper>
    ))

    return (
      <Space gap={isMobile ? '2rem' : '3.5rem'}>
        {content}
        <Text
          color={PRIMARY_COLOR}
          fontWeight={MEDIUM_FONT_WEIGHT}
          fontSize={TEXT_FONT_SIZE}
          cursor={'pointer'}
        >
          <Link className="link" to={'/feature/mobile-app'}>
            Learn About All Features
          </Link>
          <BsArrowRight color={PRIMARY_COLOR} />
        </Text>
      </Space>
    )
  }

  const renderWebFeatureDescription = () => {
    const content = webFeatureDescription.map((item, idx) => (
      <Flex
        backgroundColor={LIGHT_BG_GRAY_COLOR}
        padding={isMobile ? '1.5rem' : '2rem'}
        flex={isMobile ? '100%' : '40%'}
        margin={isMobile ? '.5rem' : '1.5rem'}
        borderRadius={'6px'}
        key={idx}
      >
        <Space gap="1.5rem">
          <Space gap=".5rem">
            <Text
              color={DARK_BLUE_COLOR}
              fontSize={
                isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
              }
              fontWeight={EXTRA_BOLD_FONT_WEIGHT}
            >
              {item.title}
            </Text>
            <Text
              color={LIGHT_GRAY_COLOR}
              fontSize={isMobile ? MOB_TEXT_FONT_SIZE : TEXT_FONT_SIZE}
              fontWeight={MEDIUM_FONT_WEIGHT}
              maxWidth={'458px'}
            >
              {item.description}
            </Text>
          </Space>
          <Wrapper margin="auto" width={isMobile ? '100%' : '90%'}>
            <Image
              src={item.image}
              width={'100%'}
              height={'100%'}
              objectFit={'cover'}
              objectPosition={'50% 50%'}
            />
          </Wrapper>
        </Space>
      </Flex>
    ))

    return (
      <Flex margin="2rem 0 0 0" flexWrap="wrap">
        {content}
      </Flex>
    )
  }

  return (
    <>
      {/* feature section 1 */}
      <Section
        margin={`${isMobile || isTablet ? '0' : '2rem '} 0 0 0`}
        padding={'0 0 2rem'}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <Text
                textAlign="center"
                fontSize={
                  isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_2_FONT_SIZE
                }
                color={SECONDARY_COLOR}
                fontWeight={EXTRA_BOLD_FONT_WEIGHT}
                width={'auto'}
              >
                Data Quality and Productivity, Improved
              </Text>
              <Text
                textAlign="center"
                fontSize={
                  isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
                }
                color={MEDIUM_GRAY_COLOR}
                fontWeight={REGULAR_FONT_WEIGHT}
                maxWidth={'859px'}
                margin="1rem auto"
              >
                We believe field work has its challenges. Thus, we aim to
                improve data quality, its gathering process and of course, your
                sales agent productivity.
              </Text>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Relative
                width={isMobile ? '100%' : '83%'}
                margin="auto"
                left={isMobile || isTablet ? '0' : '100px'}
              >
                <Image
                  src={`${Constants.general.PUBLIC_URL}/assets/images/app-sales-preview-1.png`}
                  width={'100%'}
                  height={'100%'}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Relative>
            </Col>
            <Col
              lg={6}
              className={'d-flex justify-content-center align-items-center'}
            >
              {renderAppFeatureDescription()}
            </Col>
          </Row>
        </Container>
      </Section>

      {/* feature section 2 */}
      <Section
        margin={`${isMobile || isTablet ? '0' : '2rem '} 0 0 0`}
        padding={'0 0 2rem'}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <Text
                textAlign="center"
                fontSize={
                  isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_2_FONT_SIZE
                }
                color={SECONDARY_COLOR}
                fontWeight={EXTRA_BOLD_FONT_WEIGHT}
                width={'auto'}
              >
                Organize Every Matter, Track Every Progress
              </Text>
            </Col>
            <Col lg={12}>
              {renderWebFeatureDescription()}
              <Text
                color={PRIMARY_COLOR}
                fontWeight={MEDIUM_FONT_WEIGHT}
                fontSize={TEXT_FONT_SIZE}
                textAlign="center"
                margin=".5rem 0 0 0"
              >
                <Link className="link" to={'/feature/admin-panel'}>
                  Learn About All Features
                </Link>
                <BsArrowRight color={PRIMARY_COLOR} />
              </Text>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Features
