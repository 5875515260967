// Color
export const WHITE_COLOR = '#fff'
export const BLACK_COLOR = '#000000D9'
export const PRIMARY_COLOR = '#036EFF'
export const LIGHT_BG_PRIMARY_COLOR = '#F5FAFF'
export const LIGHT_BG_GRAY_COLOR = '#FAFAFC'
export const SECONDARY_COLOR = '#122C4F'
export const DARK_BLUE_COLOR = '#034D82' // title
export const DARK_GRAY_COLOR = '#4A5F70'
export const MEDIUM_GRAY_COLOR = '#70818F'
export const LIGHT_GRAY_COLOR = '#9E9E9E'

export const DISABLED_COLOR = '#bcbcbc'

export const RED_COLOR = '#F24242'
export const GREEN_COLOR = '#00B585'
export const MAGENTA_COLOR = '#DE2879'
export const PURPLE_COLOR = '#B33CDB'
export const ORANGE_COLOR = '#F2994A'
export const BLUE_COLOR = '#3583EE'

// Fonts
export const TEXT_FONT_SIZE = '1rem' // 16px
export const TITLE_FONT_SIZE = '2rem' // 32px
export const HEADING_4_FONT_SIZE = '1.5rem' // 24px
export const HEADING_3_FONT_SIZE = '2.25rem' // 36px
export const HEADING_2_FONT_SIZE = '2.625rem' // 42px
export const HEADING_1_FONT_SIZE = '3rem' // 48px
export const DISPLAY_FONT_SIZE = '4.5rem' // 72px

export const SMALL_FONT_SIZE = '.8rem' // 12px

export const MOB_TEXT_FONT_SIZE = '.875rem' // 14px
export const MOB_HEADING_4_FONT_SIZE = '1rem' // 16px
export const MOB_HEADING_2_FONT_SIZE = '1.5rem' // 24px
export const MOB_HEADING_1_FONT_SIZE = '1.75rem' // 28px

export const REGULAR_FONT_WEIGHT = 400
export const MEDIUM_FONT_WEIGHT = 500
export const SEMI_BOLD_FONT_WEIGHT = 600
export const BOLD_FONT_WEIGHT = 700
export const EXTRA_BOLD_FONT_WEIGHT = 800

export type FontStyleType = 'normal' | 'italic' | 'oblique'
export type FontWeightType = 100 | 400 | 500 | 600 | 700 | 800 | 900
// extra bold -> 800, bold -> 700, semi bold -> 600, medium -> 500, regular -> 400
export type TextTransformType =
  | 'uppercase'
  | 'lowercase'
  | 'capitalize'
  | 'initial'
