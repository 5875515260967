import { useApp } from 'contexts/app-context/use-app'
import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  Button,
  Flex,
  Image,
  Relative,
  Section,
  Space,
  Text,
  Wrapper,
} from 'styles/styled-components'
import {
  DARK_GRAY_COLOR,
  EXTRA_BOLD_FONT_WEIGHT,
  HEADING_2_FONT_SIZE,
  HEADING_4_FONT_SIZE,
  LIGHT_BG_PRIMARY_COLOR,
  MEDIUM_GRAY_COLOR,
  MOB_HEADING_2_FONT_SIZE,
  MOB_HEADING_4_FONT_SIZE,
  MOB_TEXT_FONT_SIZE,
  REGULAR_FONT_WEIGHT,
  SECONDARY_COLOR,
  SEMI_BOLD_FONT_WEIGHT,
  TEXT_FONT_SIZE,
} from 'styles/styled-components/variables'
import Constants from '../../../../constants'

const Benefits: FunctionComponent = () => {
  const { isMobile, isTablet } = useApp()

  const benefits = [
    {
      title: 'Well Grounded Systems',
      description:
        'Our system has 99% operational uptime & complies with the highest standards, ensuring a smooth & efficient usage.',
      icon: `${Constants.general.PUBLIC_URL}/assets/icons/fingerprints.svg`,
    },
    {
      title: 'Nationwide Implementation',
      description:
        'We have implemented our solution in several cities nationwide. And we won’t stop there.',
      icon: `${Constants.general.PUBLIC_URL}/assets/icons/nationwide.svg`,
    },
  ]

  const renderBenefits = () => {
    const content = benefits.map((item, idx) => (
      <Wrapper key={idx} margin={isMobile ? '1rem auto' : 'auto'}>
        <Space gap="1.5rem">
          <Flex justifyContent="center">
            <Image
              src={item.icon}
              width={'48px'}
              height={'48px'}
              objectFit={'cover'}
              objectPosition={'50% 50%'}
            />
          </Flex>
          <Space gap=".5rem">
            <Text
              color={SECONDARY_COLOR}
              fontSize={
                isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
              }
              fontWeight={SEMI_BOLD_FONT_WEIGHT}
              textAlign={'center'}
            >
              {item.title}
            </Text>
            <Text
              color={MEDIUM_GRAY_COLOR}
              fontSize={isMobile ? MOB_TEXT_FONT_SIZE : TEXT_FONT_SIZE}
              fontWeight={REGULAR_FONT_WEIGHT}
              maxWidth={'310px'}
              textAlign={'center'}
            >
              {item.description}
            </Text>
          </Space>
        </Space>
      </Wrapper>
    ))

    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        margin={isMobile ? 'auto' : 'auto 2rem'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {content}
      </Flex>
    )
  }

  return (
    <>
      <Section
        margin={`${isMobile || isTablet ? '0' : '4rem '} 0 0 0`}
        padding={'0 0 2rem'}
      >
        <Container className="section-container objective">
          <Row className="mx-auto">
            <Col lg={12}>
              <Text
                textAlign="center"
                fontSize={
                  isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_2_FONT_SIZE
                }
                color={SECONDARY_COLOR}
                fontWeight={EXTRA_BOLD_FONT_WEIGHT}
              >
                Cutting Edge Technology, Driven Platform
              </Text>
            </Col>
          </Row>
        </Container>

        <Relative
          margin={`${isMobile ? '.5rem' : '2rem'} 0 0 0`}
          left={isMobile ? '0' : '5%'}
        >
          <Image
            src={`${Constants.general.PUBLIC_URL}/assets/images/maps-objective.png`}
            width={'100%'}
            height={'100%'}
            objectFit={'cover'}
            objectPosition={'50% 50%'}
          />
        </Relative>

        <Wrapper
          backgroundColor={LIGHT_BG_PRIMARY_COLOR}
          margin={'4rem 0 0 0'}
          padding={isMobile ? '2rem' : '4rem'}
        >
          <Container>
            <Row>
              <Col lg={12}>{renderBenefits()}</Col>
            </Row>
          </Container>
        </Wrapper>
      </Section>

      <Section
        margin={`${isMobile || isTablet ? '0' : '4rem '} 0 ${
          isMobile || isTablet ? '0' : '4rem '
        } 0`}
        padding={'0 0 2rem'}
      >
        <Container>
          <Row className={isMobile ? 'd-flex flex-column-reverse' : ''}>
            <Col
              sm={12}
              md={12}
              lg={6}
              className={'d-flex justify-content-center align-items-center'}
            >
              <Space gap="3.5rem">
                <Space gap="1rem">
                  <Text
                    fontSize={
                      isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_2_FONT_SIZE
                    }
                    color={SECONDARY_COLOR}
                    fontWeight={EXTRA_BOLD_FONT_WEIGHT}
                  >
                    Never Worry About Your Data Security
                  </Text>
                  <Text
                    fontSize={isMobile ? TEXT_FONT_SIZE : HEADING_4_FONT_SIZE}
                    color={DARK_GRAY_COLOR}
                    fontWeight={REGULAR_FONT_WEIGHT}
                    maxWidth={'554px'}
                  >
                    Data security is always on top of our mind. Learn more about
                    our approach on your data security.
                  </Text>
                </Space>
                <Wrapper>
                  <Button>
                    <Link to={'/safe-data'}>Learn More</Link>
                  </Button>
                </Wrapper>
              </Space>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Wrapper width={'80%'} margin="auto">
                <Image
                  src={`${Constants.general.PUBLIC_URL}/assets/images/safe-data-benefit.svg`}
                  width={'100%'}
                  height={'100%'}
                />
              </Wrapper>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Benefits
