import { useMutation } from '@apollo/client'
import Button from 'components/controls/button'
import { Notification } from 'components/notification'
import { useApp } from 'contexts/app-context/use-app'
import React, { FunctionComponent, useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import {
  BackgroundImage,
  Section,
  Space,
  Text,
  TextSpan,
  Wrapper,
} from 'styles/styled-components'
import {
  BOLD_FONT_WEIGHT,
  EXTRA_BOLD_FONT_WEIGHT,
  HEADING_2_FONT_SIZE,
  HEADING_4_FONT_SIZE,
  MEDIUM_FONT_WEIGHT,
  MOB_HEADING_2_FONT_SIZE,
  MOB_TEXT_FONT_SIZE,
  RED_COLOR,
  SECONDARY_COLOR,
  SEMI_BOLD_FONT_WEIGHT,
  TEXT_FONT_SIZE,
  WHITE_COLOR,
} from 'styles/styled-components/variables'
import { loginValidation } from 'utils/validation'
import Constants from '../../../../constants'

import '../../../../styles/components/demo-registration.scss'
import { SUBMIT_REQ_DEMO } from './queries'

const DemoRegistration: FunctionComponent = () => {
  const notificationRef: any = useRef(null)

  const { isMobile, isTablet } = useApp()
  const [data, setData] = useState({
    name: '',
    email: '',
    organization: '',
    phone_number: '',
    message: '',
  })
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    organization: '',
    phone_number: '',
    message: '',
  })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false)

  const [onSubmitLogin] = useMutation(SUBMIT_REQ_DEMO, {
    onCompleted: (data) => {
      if (data?.requestDemo) {
        setData({
          name: '',
          email: '',
          organization: '',
          phone_number: '',
          message: '',
        })
        setIsSubmitSuccess(true)
        setIsSubmitted(false)
      }
    },
    onError: (error) => {
      notificationRef.current.addNotification({
        title: 'Failed to submit request demo',
        message: error.message,
        level: 'error',
      })
      setIsSubmitted(false)
    },
    notifyOnNetworkStatusChange: true,
  })

  const onClickSubmitRequestDemo = async (e: MouseEvent) => {
    e.preventDefault()
    setIsSubmitted(true)

    const errorValidation = loginValidation(data)

    if (Object.keys(errorValidation).length > 0) {
      setErrors(errorValidation)
      setIsSubmitted(false)
    } else {
      setErrors({
        name: '',
        email: '',
        organization: '',
        phone_number: '',
        message: '',
      })
      const variables = data

      await onSubmitLogin({ variables })
    }
  }

  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
  ) => {
    const value: any = e.currentTarget?.value
    setData({
      ...data,
      [key]: value,
    })
    setErrors({
      ...errors,
      [key]: '',
    })
  }

  const renderForm = () => {
    return (
      <Wrapper margin={isMobile || isTablet ? '1.5rem' : '4.5rem 5rem 0 5rem'}>
        <Form className="demo-regist__form">
          <Row>
            <Form.Group
              as={Col}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="form-col"
            >
              <Form.Control
                placeholder="First Name"
                required
                value={data.name}
                onChange={(e) => onChangeInput(e as any, 'name')}
              />
              {errors?.name && (
                <TextSpan color={RED_COLOR}>{errors?.name}</TextSpan>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="form-col"
            >
              <Form.Control
                placeholder="Email Address"
                required
                value={data.email}
                onChange={(e) => onChangeInput(e as any, 'email')}
              />
              {errors?.email && (
                <TextSpan color={RED_COLOR}>{errors?.email}</TextSpan>
              )}
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="form-col"
            >
              <Form.Control
                placeholder="Organization"
                required
                value={data.organization}
                onChange={(e) => onChangeInput(e as any, 'organization')}
              />
              {errors?.organization && (
                <TextSpan color={RED_COLOR}>{errors?.organization}</TextSpan>
              )}
            </Form.Group>
            <Form.Group
              as={Col}
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="form-col"
            >
              <Form.Control
                placeholder="Phone Number"
                required
                value={data.phone_number}
                onChange={(e) => onChangeInput(e as any, 'phone_number')}
              />
              {errors?.phone_number && (
                <TextSpan color={RED_COLOR}>{errors?.phone_number}</TextSpan>
              )}
            </Form.Group>
          </Row>

          <Row>
            <Form.Group
              as={Col}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className="form-col"
            >
              <Form.Control
                as="textarea"
                placeholder="Message"
                rows={4}
                required
                value={data.message}
                onChange={(e) => onChangeInput(e as any, 'message')}
              />
              {errors?.message && (
                <TextSpan color={RED_COLOR}>{errors?.message}</TextSpan>
              )}
            </Form.Group>
          </Row>

          <Space gap="1rem">
            <Button
              fontColor={WHITE_COLOR}
              backgroundColor={SECONDARY_COLOR}
              fontWeight={BOLD_FONT_WEIGHT}
              fullWidth
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                onClickSubmitRequestDemo(e as any)
              }
              isLoading={isSubmitted}
            >
              Request Demo
            </Button>

            {isSubmitSuccess && (
              <Text
                color={WHITE_COLOR}
                fontWeight={SEMI_BOLD_FONT_WEIGHT}
                fontSize={isMobile ? MOB_TEXT_FONT_SIZE : HEADING_4_FONT_SIZE}
                textAlign={'center'}
              >
                Thank you, we will get back to you shortly!
              </Text>
            )}
          </Space>
        </Form>
      </Wrapper>
    )
  }

  return (
    <>
      <Notification ref={notificationRef} />
      <Section padding={'3.5rem 0 3.5rem 0'} id="request-demo">
        <Container>
          <Row>
            <Col>
              <Wrapper
                height="100%"
                borderRadius="8px"
                overflow="hidden"
                maxWidth="1076px"
                margin="auto"
              >
                <BackgroundImage
                  backgroundImgSrc={`${Constants.general.PUBLIC_URL}/assets/images/demo-reg-bg.png`}
                  padding={
                    isMobile || isTablet ? '1.5rem 0 1.5rem 0' : '5rem 0 5rem 0'
                  }
                >
                  <Wrapper margin={isMobile || isTablet ? '0 1.5rem' : '0'}>
                    <Space>
                      <Text
                        textAlign="center"
                        fontSize={
                          isMobile || isTablet
                            ? MOB_HEADING_2_FONT_SIZE
                            : HEADING_2_FONT_SIZE
                        }
                        fontWeight={EXTRA_BOLD_FONT_WEIGHT}
                        color={WHITE_COLOR}
                      >
                        Request Demo, Pricing & Consultation
                      </Text>
                      <Text
                        textAlign="center"
                        fontSize={
                          isMobile || isTablet
                            ? MOB_TEXT_FONT_SIZE
                            : TEXT_FONT_SIZE
                        }
                        fontWeight={MEDIUM_FONT_WEIGHT}
                        color={WHITE_COLOR}
                        opacity={0.6}
                      >
                        Interested in trying out Onlead? Enter your details
                        below to book your free demo & consultation!
                      </Text>
                    </Space>
                  </Wrapper>
                  {renderForm()}
                </BackgroundImage>
              </Wrapper>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default DemoRegistration
