import React, { FunctionComponent } from 'react'
import Features from './components/features'

import Header from './components/header'
import Benefits from './components/benefits'
import Pricing from './components/pricing'
import DemoRegistration from './components/demo-registration'

const HomePage: FunctionComponent = () => {
  return (
    <>
      <Header />
      <Features />
      <Benefits />
      {/* <Pricing /> */}
      <DemoRegistration />
    </>
  )
}

export default HomePage
