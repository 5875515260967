import { useApp } from 'contexts/app-context/use-app'
import React, {
  FunctionComponent,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import Footer from '../footer'
import NavigationBar from '../navigation-bar'

interface IMainLayoutProps {}

const MainLayout: FunctionComponent<IMainLayoutProps> = ({ children }) => {
  const { setIsMobile, setIsTablet } = useApp()
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)

  const isMobile = windowWidth < 768
  const isTablet = windowWidth >= 768 && windowWidth < 992

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth)
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    setIsMobile({ isMobile })
  }, [isMobile, setIsMobile])

  useEffect(() => {
    setIsTablet({ isTablet })
  }, [isTablet, setIsTablet])

  return (
    <>
      <header>
        <NavigationBar isMobile={isMobile} isTablet={isTablet} />
      </header>
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>
    </>
  )
}

export default MainLayout
