import { AppActionsType, AppEnumType } from './actions'
import { IAppState } from './state'

export const appReducer = (
  state: IAppState,
  action: AppActionsType,
): IAppState => {
  switch (action.type) {
    case AppEnumType.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload.isMobile,
      }

    case AppEnumType.SET_IS_TABLET:
      return {
        ...state,
        isTablet: action.payload.isTablet,
      }

    default:
      return state
  }
}
