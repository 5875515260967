import { useApp } from 'contexts/app-context/use-app'
import { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Absolute, Image, Section, Space, Text } from 'styles/styled-components'
import {
  DARK_GRAY_COLOR,
  GREEN_COLOR,
  HEADING_1_FONT_SIZE,
  HEADING_4_FONT_SIZE,
  MAGENTA_COLOR,
  MOB_HEADING_2_FONT_SIZE,
  MOB_HEADING_4_FONT_SIZE,
  ORANGE_COLOR,
  PURPLE_COLOR,
  REGULAR_FONT_WEIGHT,
  SECONDARY_COLOR,
  SEMI_BOLD_FONT_WEIGHT,
  SMALL_FONT_SIZE,
  TEXT_FONT_SIZE,
} from 'styles/styled-components/variables'

import Constants from '../../../../../constants'

interface IFeatures {
  label: string
  title: string
  description: string
  image: string
  labelColor: string
}

const MobileAppFeatures: FunctionComponent = () => {
  const { isMobile, isTablet } = useApp()
  const appFeatureDescription: IFeatures[] = [
    {
      label: 'ATTENDANCE',
      title: 'Check-in & Check-out',
      description:
        'Enable your sales army to check in & check out only by real time location & selfie.',
      image: '/assets/images/features/attendance.png',
      labelColor: PURPLE_COLOR,
    },
    {
      label: 'ASSIGNMENT',
      title: 'Sales To Do List',
      description:
        'Check every assignment, equipped with lead’s detail and Google Maps integration.',
      image: '/assets/images/features/assignment.png',
      labelColor: MAGENTA_COLOR,
    },
    {
      label: 'VALIDATION',
      title: 'Real Time Geolocation Validation',
      description: 'Ensure the exact location of your sales army in real time.',
      image: '/assets/images/features/realtime-geolocation.png',
      labelColor: ORANGE_COLOR,
    },
    {
      label: 'APPROVAL',
      title: 'Submission Approval',
      description:
        'Allow your sales army to keep an eye on their submission approval status.',
      image: '/assets/images/features/submission-approval.png',
      labelColor: GREEN_COLOR,
    },
  ]

  const renderFeature = () => {
    const content = appFeatureDescription.map(
      (item: IFeatures, idx: number) => {
        const wordingContent = (
          <Space>
            <Text
              color={item.labelColor}
              fontWeight={SEMI_BOLD_FONT_WEIGHT}
              fontSize={isMobile ? SMALL_FONT_SIZE : TEXT_FONT_SIZE}
              letterSpacing={'.2rem'}
            >
              {item.label}
            </Text>
            <Text
              fontSize={
                isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_1_FONT_SIZE
              }
              fontWeight={SEMI_BOLD_FONT_WEIGHT}
              color={SECONDARY_COLOR}
            >
              {item.title}
            </Text>
            <Text
              fontSize={
                isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
              }
              fontWeight={REGULAR_FONT_WEIGHT}
              color={DARK_GRAY_COLOR}
              maxWidth={'500px'}
              margin={isTablet ? 'auto' : ''}
            >
              {item.description}
            </Text>
          </Space>
        )
        if (idx % 2 === 0) {
          return (
            <Row
              key={idx}
              style={{ marginBottom: isMobile ? '3rem' : '135px' }}
            >
              <Col
                lg={6}
                className={
                  isTablet || isMobile ? 'd-flex justify-content-center' : ''
                }
              >
                <Image
                  src={`${Constants.general.PUBLIC_URL}${item.image}`}
                  width={isTablet ? '440px' : isMobile ? '310px' : '540px'}
                  height={'100%'}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Col>
              <Col
                lg={6}
                className={`d-flex align-items-center mt-3 ${
                  isTablet ? 'text-center' : ''
                }`}
              >
                {wordingContent}
              </Col>
            </Row>
          )
        } else {
          return (
            <Row
              key={idx}
              style={{ marginBottom: isMobile ? '3rem' : '135px' }}
              className={
                isTablet || isMobile ? 'd-flex flex-column-reverse' : ''
              }
            >
              <Col
                lg={6}
                className={`d-flex align-items-center mt-3 ${
                  isTablet ? 'text-center' : ''
                }`}
              >
                {wordingContent}
              </Col>
              <Col
                lg={6}
                className={
                  isTablet || isMobile ? 'd-flex justify-content-center' : ''
                }
              >
                <Image
                  src={`${Constants.general.PUBLIC_URL}${item.image}`}
                  width={isMobile ? '310px' : '540px'}
                  height={'100%'}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Col>
            </Row>
          )
        }
      },
    )

    return content
  }

  return (
    <>
      <Section margin={`${isMobile || isTablet ? '4rem' : '5rem '} 0 0 0`}>
        <Absolute
          top="22%"
          left="-1%"
          zIndex={-1}
          className={isMobile || isTablet ? 'd-none' : ''}
        >
          <Image
            src={`${Constants.general.PUBLIC_URL}/assets/images/features/dashed-ornament.svg`}
            width={'100%'}
            height={'100%'}
          />
        </Absolute>
        <Container>{renderFeature()}</Container>
      </Section>
    </>
  )
}

export default MobileAppFeatures
