import { gql } from '@apollo/client'

export const SUBMIT_REQ_DEMO = gql`
  mutation requestDemo(
    $name: String
    $email: String
    $organization: String
    $phone_number: String
    $message: String
  ) {
    requestDemo(
      name: $name
      email: $email
      organization: $organization
      phone_number: $phone_number
      message: $message
    ) {
      id
      name
    }
  }
`
