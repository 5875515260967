import React, { FunctionComponent } from 'react'
import { Spinner } from 'react-bootstrap'
import {
  FontWeightType,
  TextTransformType,
} from 'styles/styled-components/variables'
import { Button as StyledButton } from '../../../styles/styled-components'

interface IButtonProps {
  width?: string
  fullWidth?: boolean
  margin?: string
  padding?: string

  backgroundColor?: string

  fontColor?: string
  fontSize?: string
  fontWeight?: FontWeightType
  textTransform?: TextTransformType

  border?: string
  borderRadius?: string

  disabled?: boolean
  isLoading?: boolean

  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  type?: 'submit' | 'reset' | 'button'
}

const Button: FunctionComponent<IButtonProps> = ({
  fullWidth,
  textTransform,
  borderRadius,
  padding,
  children,
  width,
  margin,
  disabled,
  onClick,
  border,
  backgroundColor,
  fontColor,
  isLoading = false,
  type,
  ...props
}) => {
  const buttonContent = isLoading ? (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
    />
  ) : (
    children
  )
  return (
    <StyledButton
      {...props}
      textTransform={textTransform}
      borderRadius={borderRadius}
      padding={padding}
      fullWidth={fullWidth}
      width={width}
      margin={margin}
      onClick={disabled || isLoading ? () => null : onClick}
      border={border}
      backgroundColor={backgroundColor}
      fontColor={fontColor}
      type={type}
    >
      {buttonContent}
    </StyledButton>
  )
}

export default Button
