import { useApp } from 'contexts/app-context/use-app'
import { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Section } from 'styles/styled-components'

import Constants from '../../constants'

const TermsAndConditions: FunctionComponent = () => {
  const { isMobile } = useApp()
  return (
    <Section margin={`${isMobile ? '80px 0 1rem 0' : '5.5rem 0 3rem 0'} `}>
      <Container>
        <Row>
          <Col>
            <iframe
              title="Terms & Conditions"
              style={{ height: '100vh' }}
              width={'100%'}
              src={`${Constants.general.PUBLIC_URL}/assets/html/terms-and-conditions.html`}
            ></iframe>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default TermsAndConditions
