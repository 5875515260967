import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  BLACK_COLOR,
  DISABLED_COLOR,
  FontStyleType,
  FontWeightType,
  SECONDARY_COLOR,
  TextTransformType,
} from './variables'

interface IStyledComponentProps {
  disabled?: boolean

  backgroundColor?: string
  linearGradient?: string
  color?: string
  opacity?: number

  width?: string
  fullWidth?: boolean
  height?: string
  zIndex?: number
  maxWidth?: string
  margin?: string
  padding?: string
  gap?: string

  cursor?: string
  objectFit?: string
  objectPosition?: string

  position?: string
  left?: string
  top?: string
  right?: string
  bottom?: string

  border?: string
  borderRadius?: string
  borderBottom?: string
  borderRight?: string
  borderColor?: string

  display?: string
  flex?: string
  justifyContent?: string
  alignItems?: string
  flexDirection?: string
  flexWrap?: string

  transform?: string
  overflow?: string
  whitespace?: string

  fontColor?: string
  fontSize?: string
  fontWeight?: FontWeightType
  fontStyle?: FontStyleType
  textDecoration?: string
  textAlign?: string
  textTransform?: TextTransformType

  letterSpacing?: string
  uppercase?: string
  backgroundImgSrc?: string
  columns?: number
  boxShadow?: string
}

const Wrapper = styled.div<IStyledComponentProps>`
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
  border: ${(props) => props.border};
  max-width: ${(props) => props.maxWidth};
  overflow: ${(props) => props.overflow};
  columns: ${(props) => props.columns};
  -webkit-columns: ${(props) => props.columns};
  background-image: linear-gradient(${(props) => props.linearGradient});
`

const Relative = styled.div<IStyledComponentProps>`
  position: relative;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
  border: ${(props) => props.border};
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
`

const Fixed = styled.div<IStyledComponentProps>`
  position: fixed;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
`

const Absolute = styled.div<IStyledComponentProps>`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  cursor: ${(props) => props.cursor};
  transform: ${(props) => props.transform};
  z-index: ${(props) => props.zIndex};
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => props.margin};
`

const Image = styled.img<IStyledComponentProps>`
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height : 'auto')};
  margin: ${(props) => props.margin};
  object-fit: ${(props) => props.objectFit};
  cursor: ${(props) => props.cursor};
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  right: ${(props) => props.right}
  bottom: ${(props) => props.bottom}
  border-radius: ${(props) => props.borderRadius};
  display: ${(props) => props.display};
  object-position: ${(props) => props.objectPosition}
  max-width: ${(props) => props.maxWidth};
`

const Flex = styled.div<IStyledComponentProps>`
  max-width: ${(props) => props.maxWidth};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  flex-direction: ${(props) => props.flexDirection};
  border-bottom: ${(props) => props.borderBottom};
  border-right: ${(props) => props.borderRight};
  border-radius: ${(props) => props.borderRadius};
  overflow: ${(props) => props.overflow};
  white-space: ${(props) => props.whitespace};
  opacity: ${(props) => props.opacity};
  cursor: ${(props) => props.cursor};
  flex-wrap: ${(props) => props.flexWrap};
  flex: ${(props) => props.flex};
  background-image: linear-gradient(${(props) => props.linearGradient});
`

const defaultButtonStyle = css`
  display: inline-block;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  outline: none;
  box-shadow: none;
  transition: 0.1s all;
  &:focus,
  &:active:focus,
  &.active:focus {
    outline: none;
    box-shadow: none;
  }
`

const Button = styled.button<IStyledComponentProps>`
  ${defaultButtonStyle}
  border: ${(props) => props.border || '1px solid transparent'};
  text-transform: ${(props) => props.textTransform};
  border-radius: ${(props) => props.borderRadius || '6px'};
  padding: ${(props) => props.padding || '1rem 2rem'};
  margin: ${(props) => props.margin || '0'};
  width: ${(props) => (props.fullWidth ? '100%' : props.width)};
  font-size: ${(props) => props.fontSize || '16px'};
  background-color: ${(props) =>
    props.disabled
      ? DISABLED_COLOR
      : props.backgroundColor
      ? props.backgroundColor
      : SECONDARY_COLOR};
  color: ${(props) => props.fontColor || '#fff'};
  font-weight: ${(props) => props.fontWeight || 600};
`

const Text = styled.div<IStyledComponentProps>`
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color || BLACK_COLOR};
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => (props.display ? props.display : 'block')};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whitespace};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  letter-spacing: ${(props) => props.letterSpacing};
`
const TextSpan = styled.span<IStyledComponentProps>`
  font-size: ${(props) => props.fontSize};
  margin: ${(props) => props.margin};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  color: ${(props) => props.color || BLACK_COLOR};
  font-style: ${(props) => props.fontStyle};
  text-transform: ${(props) => props.textTransform};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => (props.display ? props.display : 'block')};
  padding: ${(props) => props.padding};
  opacity: ${(props) => props.opacity};
  white-space: ${(props) => props.whitespace};
  width: ${(props) => props.width};
  max-width: ${(props) => props.maxWidth};
  letter-spacing: ${(props) => props.letterSpacing};
`

const Section = styled.section<IStyledComponentProps>`
  position: ${(props) => (props.position ? props.position : 'relative')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => (props.width ? props.width : '100%')};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor};
  border: ${(props) => props.border};
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  height: ${(props) => props.height};
`

const Space = styled.div<IStyledComponentProps>`
  display: ${(props) => props.display || 'inline-flex'};
  gap: ${(props) => props.gap || '.5rem'};
  flex-direction: ${(props) => props.flexDirection || 'column'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  align-items: ${(props) => props.alignItems};
  flex-wrap: ${(props) =>
    props.flexDirection === 'row' ? 'wrap' : props.flexWrap};
  width: ${(props) => (props.width ? props.width : '100%')};
`

const BackgroundImage = styled.div<IStyledComponentProps>`
  background-image: url('${(props) => props.backgroundImgSrc}');
  background-position: center center;
  background-size: cover;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  position: ${(props) => props.position};
`

const IconWrapper = styled.div<IStyledComponentProps>`
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  box-shadow: ${(props) => props.boxShadow};
  cursor: ${(props) => props.cursor};
`

export {
  Wrapper,
  Relative,
  Absolute,
  Fixed,
  Image,
  Flex,
  Button,
  Text,
  Section,
  Space,
  BackgroundImage,
  IconWrapper,
  TextSpan,
}
