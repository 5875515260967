import { useApp } from 'contexts/app-context/use-app'
import DemoRegistration from 'pages/home/components/demo-registration'
import { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Image, Section, Space, Text } from 'styles/styled-components'
import {
  BOLD_FONT_WEIGHT,
  DARK_GRAY_COLOR,
  HEADING_2_FONT_SIZE,
  HEADING_3_FONT_SIZE,
  HEADING_4_FONT_SIZE,
  MEDIUM_FONT_WEIGHT,
  MOB_HEADING_2_FONT_SIZE,
  MOB_HEADING_4_FONT_SIZE,
  REGULAR_FONT_WEIGHT,
  SECONDARY_COLOR,
  SEMI_BOLD_FONT_WEIGHT,
} from 'styles/styled-components/variables'

import Constants from '../../constants'

interface IAboutPageProps {}

const AboutPage: FunctionComponent<IAboutPageProps> = () => {
  const { isMobile, isTablet } = useApp()
  return (
    <>
      <Section padding={`${isMobile ? '92px 0 1rem 0' : '8rem 0 3rem 0'} `}>
        <Container>
          <Row style={{ marginBottom: isMobile ? '40px' : '138px' }}>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Text
                fontSize={
                  isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_2_FONT_SIZE
                }
                color={SECONDARY_COLOR}
                fontWeight={BOLD_FONT_WEIGHT}
                textAlign={'center'}
              >
                About Onlead
              </Text>
              <Text
                fontSize={
                  isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_3_FONT_SIZE
                }
                color={DARK_GRAY_COLOR}
                fontWeight={MEDIUM_FONT_WEIGHT}
                textAlign={'center'}
              >
                We provide practical & effective tools for your sales management
                & activities.
              </Text>
            </Col>
          </Row>

          <Row
            style={{ marginBottom: isMobile ? '24px' : '138px' }}
            className={`${isMobile || isTablet ? 'flex-column-reverse' : ''}`}
          >
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className={`d-flex align-items-center`}
              style={{ marginTop: isMobile ? '1.5rem' : '' }}
            >
              <Space>
                <Text
                  fontSize={
                    isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_2_FONT_SIZE
                  }
                  color={SECONDARY_COLOR}
                  fontWeight={SEMI_BOLD_FONT_WEIGHT}
                >
                  Our Commitment
                </Text>
                <Text
                  fontSize={
                    isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
                  }
                  color={DARK_GRAY_COLOR}
                  fontWeight={REGULAR_FONT_WEIGHT}
                  maxWidth={'525px'}
                >
                  We are highly committed to provide reliable & robust
                  tech-based solutions for your company.
                </Text>
              </Space>
            </Col>
            <Col>
              <Image
                src={`${Constants.general.PUBLIC_URL}/assets/images/about-1.png`}
                width={isMobile ? '100%' : '584px'}
                height={isMobile ? '100%' : '343px'}
                objectFit={'cover'}
                objectPosition={'50% 50%'}
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: isMobile ? '24px' : '138px' }}>
            <Col xs={12} sm={12} md={12} lg={6}>
              <Image
                src={`${Constants.general.PUBLIC_URL}/assets/images/about-2.png`}
                width={isMobile ? '100%' : '584px'}
                height={isMobile ? '100%' : '343px'}
                objectFit={'cover'}
                objectPosition={'50% 50%'}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              className="d-flex align-items-center"
              style={{ marginTop: isMobile ? '1.5rem' : '' }}
            >
              <Space>
                <Text
                  fontSize={
                    isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_2_FONT_SIZE
                  }
                  color={SECONDARY_COLOR}
                  fontWeight={SEMI_BOLD_FONT_WEIGHT}
                >
                  Customer First
                </Text>
                <Text
                  fontSize={
                    isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
                  }
                  color={DARK_GRAY_COLOR}
                  fontWeight={REGULAR_FONT_WEIGHT}
                  maxWidth={'525px'}
                >
                  We always deliver our best to cater your needs, as we
                  understand every enterprise is unique & different.
                </Text>
              </Space>
            </Col>
          </Row>
        </Container>
      </Section>
      <DemoRegistration />
    </>
  )
}

export default AboutPage
