import { useApp } from 'contexts/app-context/use-app'
import { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  Absolute,
  Flex,
  Image,
  Section,
  Space,
  Text,
  Wrapper,
} from 'styles/styled-components'
import {
  BOLD_FONT_WEIGHT,
  DARK_GRAY_COLOR,
  DISPLAY_FONT_SIZE,
  EXTRA_BOLD_FONT_WEIGHT,
  HEADING_4_FONT_SIZE,
  LIGHT_BG_GRAY_COLOR,
  MEDIUM_FONT_WEIGHT,
  MOB_HEADING_2_FONT_SIZE,
  MOB_HEADING_4_FONT_SIZE,
  MOB_TEXT_FONT_SIZE,
  REGULAR_FONT_WEIGHT,
  SECONDARY_COLOR,
  SEMI_BOLD_FONT_WEIGHT,
  TEXT_FONT_SIZE,
  TITLE_FONT_SIZE,
  WHITE_COLOR,
} from 'styles/styled-components/variables'

import Constants from '../../constants'

interface IBenefits {
  icon: string
  title: string
  description: string
}

const SafeData: FunctionComponent = () => {
  const navigate = useNavigate()
  const { isMobile, isTablet } = useApp()

  const safeDataBenefits: IBenefits[] = [
    {
      icon: '/assets/icons/cloud-backup.svg',
      title: 'Constant Data Backup',
      description: 'We constantly back up data in our server, never you mind.',
    },
    {
      icon: '/assets/icons/approval.svg',
      title: 'Your Approval is Everything',
      description:
        'Onlead will never use your data for any purpose without your approval.',
    },
    {
      icon: '/assets/icons/google-cloud-platform.svg',
      title: '100% Secure Data',
      description: 'We use Google Cloud Platform for data & cloud management.',
    },
  ]

  const renderSafetyBenefits = () => {
    const content = safeDataBenefits.map((item: IBenefits, idx: number) => {
      return (
        <Flex
          backgroundColor={LIGHT_BG_GRAY_COLOR}
          borderRadius={'8px'}
          key={idx}
          padding={'4.5rem 2rem'}
          margin={isMobile ? '1rem' : '2rem'}
        >
          <Space gap="1.5rem" alignItems="center">
            <Image
              src={`${Constants.general.PUBLIC_URL}${item.icon}`}
              width={idx === 2 ? '68px' : '48px'}
              height={idx === 2 ? '68px' : '48px'}
              objectFit="cover"
              objectPosition="50% 50%"
            />
            <Space>
              <Text
                color={SECONDARY_COLOR}
                fontWeight={SEMI_BOLD_FONT_WEIGHT}
                fontSize={
                  isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
                }
                textAlign={'center'}
              >
                {item.title}
              </Text>
              <Text
                color={DARK_GRAY_COLOR}
                fontWeight={MEDIUM_FONT_WEIGHT}
                fontSize={isMobile ? MOB_TEXT_FONT_SIZE : TEXT_FONT_SIZE}
                maxWidth={'333px'}
                textAlign={'center'}
              >
                {item.description}
              </Text>
            </Space>
          </Space>
        </Flex>
      )
    })

    return (
      <Row>
        <Col>
          <Flex margin="2rem 0 0 0" flexWrap="wrap" justifyContent="center">
            {content}
          </Flex>
        </Col>
      </Row>
    )
  }

  const renderPolicyAndTnC = () => {
    const content = (
      <Flex
        borderRadius={'15px'}
        linearGradient={'136.82deg, #036EFF -28.85%, #010A16 169.19%'}
        justifyContent={'center'}
        flexWrap={isMobile ? 'wrap' : ''}
        maxWidth={'1189px'}
        margin={isMobile ? '1rem' : 'auto'}
      >
        <Flex
          backgroundColor={WHITE_COLOR}
          borderRadius={'15px'}
          padding={'2rem 1rem'}
          margin={isMobile ? '1rem' : '2.5rem'}
          cursor={'pointer'}
          onClick={() => navigate('/privacy-policy')}
        >
          <Image
            src={`${Constants.general.PUBLIC_URL}/assets/icons/privacy-policy-protect.svg`}
            width={'48px'}
            height={'48px'}
            objectFit="cover"
            objectPosition="50% 50%"
          />
          <Wrapper margin="0 0 0 1rem">
            <Text
              color={SECONDARY_COLOR}
              fontSize={
                isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
              }
              fontWeight={EXTRA_BOLD_FONT_WEIGHT}
            >
              Privacy Policy
            </Text>
            <Text
              color={DARK_GRAY_COLOR}
              fontWeight={MEDIUM_FONT_WEIGHT}
              fontSize={isMobile ? MOB_TEXT_FONT_SIZE : TEXT_FONT_SIZE}
              maxWidth={'367px'}
            >
              Click here to learn more about our Privacy Policy
            </Text>
          </Wrapper>
        </Flex>
        <Flex
          backgroundColor={WHITE_COLOR}
          borderRadius={'10px'}
          padding={'2rem 1rem'}
          margin={isMobile ? '1rem' : '2.5rem'}
          cursor={'pointer'}
        >
          <Image
            src={`${Constants.general.PUBLIC_URL}/assets/icons/terms-and-conditions.svg`}
            width={'48px'}
            height={'48px'}
            objectFit="cover"
            objectPosition="50% 50%"
          />
          <Wrapper margin="0 0 0 1rem">
            <Text
              color={SECONDARY_COLOR}
              fontSize={
                isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
              }
              fontWeight={EXTRA_BOLD_FONT_WEIGHT}
            >
              Term & Conditions
            </Text>
            <Text
              color={DARK_GRAY_COLOR}
              fontWeight={MEDIUM_FONT_WEIGHT}
              fontSize={isMobile ? MOB_TEXT_FONT_SIZE : TEXT_FONT_SIZE}
              maxWidth={'367px'}
            >
              Click here to learn more about Term & Conditions
            </Text>
          </Wrapper>
        </Flex>
      </Flex>
    )
    return (
      <Row style={{ marginTop: isMobile ? '2rem' : '110px' }}>
        <Col>{content}</Col>
      </Row>
    )
  }

  return (
    <>
      <Section
        padding={`${isTablet || isMobile ? '6rem' : '8rem'} 0 3rem 0`}
        height="100%"
      >
        <Absolute top="0">
          <Image
            src={`${Constants.general.PUBLIC_URL}/assets/images/blurred-shape.svg`}
            width={isMobile || isTablet ? '250px' : '464px'}
            height={isMobile || isTablet ? '250px' : '464px'}
            display="block"
          />
        </Absolute>
        <Container>
          <Row>
            <Col>
              <Space gap="1rem">
                <Text
                  color={SECONDARY_COLOR}
                  fontSize={
                    isTablet
                      ? TITLE_FONT_SIZE
                      : isMobile
                      ? MOB_HEADING_2_FONT_SIZE
                      : DISPLAY_FONT_SIZE
                  }
                  fontWeight={BOLD_FONT_WEIGHT}
                  textAlign="center"
                >
                  Your Data, Completely Yours
                </Text>
                <Text
                  fontWeight={REGULAR_FONT_WEIGHT}
                  fontSize={
                    isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
                  }
                  color={DARK_GRAY_COLOR}
                  textAlign={'center'}
                  maxWidth={'1048px'}
                  margin={'auto'}
                >
                  We understand that every tech-led platform needs to comply to
                  data safety regulations, and it should be transparent to each
                  of our customers.
                </Text>
              </Space>
            </Col>
          </Row>
          {renderSafetyBenefits()}
          {/* {renderPolicyAndTnC()} */}
        </Container>
      </Section>
    </>
  )
}

export default SafeData
