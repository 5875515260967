import { IAppState } from './state'

export enum AppEnumType {
  SET_IS_MOBILE = 'SET_IS_MOBILE',
  SET_IS_TABLET = 'SET_IS_TABLET',
}

export type AppActionMapType = {
  [AppEnumType.SET_IS_MOBILE]: IAppState
  [AppEnumType.SET_IS_TABLET]: IAppState
}

export type AppActionsType = {
  [Key in keyof AppActionMapType]: {
    type: Key
    payload: AppActionMapType[Key]
  }
}[keyof AppActionMapType]
