import { useApp } from 'contexts/app-context/use-app'
import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  Absolute,
  Section,
  Image,
  Text,
  Space,
  Button,
  Wrapper,
} from 'styles/styled-components'
import {
  EXTRA_BOLD_FONT_WEIGHT,
  HEADING_1_FONT_SIZE,
  HEADING_4_FONT_SIZE,
  MEDIUM_GRAY_COLOR,
  MOB_HEADING_1_FONT_SIZE,
  MOB_HEADING_4_FONT_SIZE,
  MOB_TEXT_FONT_SIZE,
  REGULAR_FONT_WEIGHT,
  SECONDARY_COLOR,
  TEXT_FONT_SIZE,
} from 'styles/styled-components/variables'
import Constants from '../../../../constants'

const Header: FunctionComponent = () => {
  const { isMobile, isTablet } = useApp()

  return (
    <Section
      padding={`${isTablet || isMobile ? '4rem' : '6rem'} 0 3rem 0`}
      height={'100%'}
    >
      <Absolute top="0">
        <Image
          src={`${Constants.general.PUBLIC_URL}/assets/images/blurred-shape.svg`}
          width={isMobile || isTablet ? '250px' : '464px'}
          height={isMobile || isTablet ? '250px' : '464px'}
          display="block"
        />
      </Absolute>

      <Container>
        <Row>
          <Col xs={12} sm={12} lg={7}>
            <Wrapper
              margin={`${isTablet || isMobile ? '2rem' : '100px'} 0 0 0`}
            >
              <Space gap={isMobile ? '2rem' : '4.5rem'}>
                <Space>
                  <Text
                    color={SECONDARY_COLOR}
                    fontWeight={EXTRA_BOLD_FONT_WEIGHT}
                    fontSize={
                      isMobile || isTablet
                        ? MOB_HEADING_1_FONT_SIZE
                        : HEADING_1_FONT_SIZE
                    }
                    // maxWidth={isMobile ? '352px' : '635px'}
                  >
                    Sales Management System, Simplified.
                  </Text>

                  <Text
                    color={MEDIUM_GRAY_COLOR}
                    fontWeight={REGULAR_FONT_WEIGHT}
                    fontSize={
                      isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
                    }
                    maxWidth={'616px'}
                  >
                    Manage your sales person, learn your customer's history,
                    simplify your sales management activities. All in just one
                    app.
                  </Text>
                </Space>
                <Space flexDirection="row" gap="24px" alignItems="center">
                  <Button
                    fontSize={isMobile ? MOB_TEXT_FONT_SIZE : TEXT_FONT_SIZE}
                  >
                    <Link to={'/#request-demo'}>Book Your Demo</Link>
                  </Button>
                  {/* <Text
                    color={MEDIUM_GRAY_COLOR}
                    fontWeight={MEDIUM_FONT_WEIGHT}
                    fontSize={isMobile ? MOB_TEXT_FONT_SIZE : TEXT_FONT_SIZE}
                  >
                    Find Out More
                  </Text> */}
                </Space>
              </Space>
            </Wrapper>
          </Col>
          <Col
            xs={12}
            sm={12}
            lg={5}
            style={{ height: isMobile ? '500px' : '650px' }}
          >
            <Wrapper margin={isMobile || isTablet ? '1rem 0 0 0' : ''}>
              <Image
                src={`${Constants.general.PUBLIC_URL}/assets/images/web-admin-preview-1.png`}
                width={isMobile ? '100%' : 'auto'}
                height={isMobile ? '500px' : '650px'}
                objectFit="cover"
                objectPosition="0% 50%"
              />
            </Wrapper>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Header
