// number only
export const isNumber = (value: any) => /^\d+$/.test(value)

// email validation rules
export const isEmail = (email: string) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(email)

// phone number validation rules
export const isPhone = (phoneNumb: string) =>
  /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/g.test(phoneNumb)

export const loginValidation = (values: any) => {
  const errors: any = {}

  Object.keys(values).forEach((key) => {
    if (key === 'phone_number') {
      if (isNumber(values[key])) {
        if (!isPhone(values[key])) {
          errors[key] = 'Phone Number is not valid'
        }
      }
    } else if (key === 'email' && !isEmail(values[key])) {
      errors[key] = 'Email is not valid'
    }
    if (!values[key]) {
      errors[key] = 'Required'
    }
  })

  return errors
}
