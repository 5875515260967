import DemoRegistration from 'pages/home/components/demo-registration'
import { FunctionComponent } from 'react'
import MobileAppFeatures from './components/features'
import Header from './components/header'

interface IMobileAppFeatureProps {}

const MobileAppFeature: FunctionComponent<IMobileAppFeatureProps> = () => {
  return (
    <>
      <Header />
      <MobileAppFeatures />
      <DemoRegistration />
    </>
  )
}

export default MobileAppFeature
