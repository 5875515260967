import { useApp } from 'contexts/app-context/use-app'
import { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FiMail, FiPhone } from 'react-icons/fi'

import {
  Flex,
  Image,
  Section,
  Space,
  Text,
  Wrapper,
} from 'styles/styled-components'
import {
  HEADING_4_FONT_SIZE,
  MOB_TEXT_FONT_SIZE,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SEMI_BOLD_FONT_WEIGHT,
  TEXT_FONT_SIZE,
} from 'styles/styled-components/variables'

import Constants from '../../../constants'
import { Link } from 'react-router-dom'

interface IFooterProps {}

const Footer: FunctionComponent<IFooterProps> = () => {
  const { isMobile, isTablet } = useApp()

  const onClickDownloadApp = (e: MouseEvent) => {
    e.preventDefault()
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.onstock.onlead'
  }

  const renderLinks = () => {
    const leftLink = [
      { title: 'Home', link: '/' },
      { title: 'Admin Panel Feature', link: '/feature/admin-panel' },
      { title: 'Mobile Panel Feature', link: '/feature/mobile-app' },
    ]
    const rightLink = [
      { title: 'About Us', link: '/about' },
      { title: 'Privacy Policy', link: '/privacy-policy' },
      { title: 'Terms & Conditions', link: '/terms-and-conditions' },
    ]

    const leftLinkText = leftLink.map((item, idx) => (
      <Link to={item.link} className="link" key={idx}>
        <Text
          key={idx}
          cursor={'pointer'}
          color={PRIMARY_COLOR}
          margin="0 0 .5rem 0"
          textDecoration="none"
        >
          {item.title}
        </Text>
      </Link>
    ))
    const rightLinkText = rightLink.map((item, idx) => (
      <Link to={item.link} className="link" key={idx}>
        <Text
          key={idx}
          cursor={'pointer'}
          color={PRIMARY_COLOR}
          margin="0 0 .5rem 0"
          textDecoration="none"
        >
          {item.title}
        </Text>
      </Link>
    ))
    return (
      <Flex
        margin={isMobile || isTablet ? '0 0 1rem 0' : ''}
        alignItems={'flex-start'}
        flexDirection={isMobile || isTablet ? 'column' : 'row'}
      >
        <Wrapper padding="0 5rem 0 0">{leftLinkText}</Wrapper>
        <Wrapper>{rightLinkText}</Wrapper>
      </Flex>
    )
  }
  return (
    <>
      <Section padding={isMobile || isTablet ? '1.5rem 0' : '5rem 0'}>
        <Container>
          <Row>
            <Col>
              <Image
                src={`${Constants.general.PUBLIC_URL}/assets/images/logo/logo-onlead-colored.svg`}
                width={isMobile ? '116px' : '280px'}
                height={isMobile ? '37px' : '67px'}
                display="block"
                margin={isMobile || isTablet ? '0' : '0 5rem'}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Flex
                justifyContent="space-between"
                alignItems={'flex-start'}
                margin={isMobile || isTablet ? '1rem 0' : '2rem 5rem'}
                flexDirection={isMobile || isTablet ? 'column' : 'row'}
              >
                <Wrapper margin={isMobile || isTablet ? '0 0 1rem 0' : ''}>
                  <Space>
                    <Text
                      fontSize={TEXT_FONT_SIZE}
                      color={SECONDARY_COLOR}
                      fontWeight={SEMI_BOLD_FONT_WEIGHT}
                    >
                      PT Onstock Solusi Indonesia
                    </Text>
                    <Text maxWidth="326px" color={SECONDARY_COLOR}>
                      <Text
                        fontSize={TEXT_FONT_SIZE}
                        fontWeight={SEMI_BOLD_FONT_WEIGHT}
                        color={SECONDARY_COLOR}
                      >
                        Operational Office:
                      </Text>{' '}
                      Jason's Market, Jl. Ampera Raya No.5, RT 5/RW 10, Cilandak
                      Timur, Kec. Ps. Minggu, Kota Jakarta Selatan, DKI Jakarta
                      12560
                    </Text>
                    <Text maxWidth="326px" color={SECONDARY_COLOR}>
                      <Text
                        fontSize={TEXT_FONT_SIZE}
                        fontWeight={SEMI_BOLD_FONT_WEIGHT}
                        color={SECONDARY_COLOR}
                      >
                        Main Office:
                      </Text>{' '}
                      Metropolitan Tower 22nd Floor, Jl. R.A. Kartini No.Kav.
                      14, RT.10/RW.4, West Cilandak, Cilandak, South Jakarta
                      City, Jakarta 12430
                    </Text>
                    <Text color={SECONDARY_COLOR}>Mon-Fri 10.00-17.00</Text>
                  </Space>
                </Wrapper>

                {renderLinks()}

                <Wrapper margin={isMobile || isTablet ? '0 0 1rem 0' : ''}>
                  <Space gap={isMobile || isTablet ? '1rem' : '2rem'}>
                    <Space>
                      <Flex>
                        <FiPhone />
                        <Text color={SECONDARY_COLOR} margin="0 0 0 .5rem">
                          +62 851 6368 7995
                        </Text>
                      </Flex>
                      <Flex cursor="pointer">
                        {' '}
                        <FiMail />
                        <a href={'mailto:hello@onstock.id'} className="link">
                          <Text color={SECONDARY_COLOR} margin="0 0 0 .5rem">
                            hello@onstock.id
                          </Text>
                        </a>
                      </Flex>
                    </Space>

                    {/* <Space flexDirection="row" gap="1rem">
                      <IconWrapper
                        backgroundColor={WHITE_COLOR}
                        width={'44px'}
                        height={'44px'}
                        borderRadius="50%"
                        boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
                        cursor="pointer"
                      >
                        <FaFacebookF size={14} color={SECONDARY_COLOR} />
                      </IconWrapper>
                      <IconWrapper
                        backgroundColor={WHITE_COLOR}
                        width={'44px'}
                        height={'44px'}
                        borderRadius="50%"
                        boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
                        cursor="pointer"
                      >
                        <FaInstagram size={14} color={SECONDARY_COLOR} />
                      </IconWrapper>
                    </Space> */}
                    <Space>
                      <Text
                        fontWeight={SEMI_BOLD_FONT_WEIGHT}
                        color={SECONDARY_COLOR}
                        fontSize={HEADING_4_FONT_SIZE}
                      >
                        Discover our app
                      </Text>
                      <Wrapper
                        cursor="pointer"
                        onClick={(e: React.MouseEvent<HTMLElement>) =>
                          onClickDownloadApp(e as any)
                        }
                      >
                        <Image
                          src={`${Constants.general.PUBLIC_URL}/assets/images/playstore.svg`}
                          width={'135px'}
                          height={'44px'}
                          display="block"
                        />
                      </Wrapper>
                    </Space>
                  </Space>
                </Wrapper>
              </Flex>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12}>
              <Wrapper margin={isTablet || isMobile ? '0' : '0 5rem'}>
                <Text color={SECONDARY_COLOR} fontSize={MOB_TEXT_FONT_SIZE}>
                  All rights reserved @onlead.com
                </Text>
              </Wrapper>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Footer
