import {
  createContext,
  FunctionComponent,
  useCallback,
  useMemo,
  useReducer,
} from 'react'
import { AppActionMapType, AppActionsType } from './actions'
import { appReducer } from './reducer'
import { IAppState, initialState } from './state'

export type Dispatcher = <
  Type extends AppActionsType['type'],
  Payload extends AppActionMapType[Type],
>(
  type: Type,
  // This line makes it so if there shouldn't be a payload then
  // you only need to call the function with the type, but if
  // there should be a payload then you need the second argument.
  ...payload: Payload extends undefined ? [undefined?] : [Payload]
) => void

export interface IAppCtxProps extends IAppState {
  dispatch: Dispatcher
}

export const AppContext = createContext<IAppCtxProps>(undefined!)

export const AppProvider: FunctionComponent = ({ children }) => {
  const [state, appDispatch] = useReducer(appReducer, initialState)

  const dispatch: Dispatcher = useCallback((type, ...payload) => {
    appDispatch({ type, payload: payload[0] } as AppActionsType)
  }, [])

  const value = useMemo(() => ({ ...state, dispatch }), [state, dispatch])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
