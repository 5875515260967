import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { client } from './graphql'
import { AppProvider } from 'contexts/app-context'
import { ApolloProvider } from '@apollo/client'

import 'bootstrap/dist/css/bootstrap.min.css'

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AppProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
