import { useApp } from 'contexts/app-context/use-app'
import { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Image, Section, Space, Text } from 'styles/styled-components'
import {
  BLUE_COLOR,
  DARK_GRAY_COLOR,
  GREEN_COLOR,
  HEADING_1_FONT_SIZE,
  HEADING_4_FONT_SIZE,
  MAGENTA_COLOR,
  MOB_HEADING_2_FONT_SIZE,
  MOB_HEADING_4_FONT_SIZE,
  ORANGE_COLOR,
  PURPLE_COLOR,
  RED_COLOR,
  REGULAR_FONT_WEIGHT,
  SECONDARY_COLOR,
  SEMI_BOLD_FONT_WEIGHT,
  SMALL_FONT_SIZE,
  TEXT_FONT_SIZE,
} from 'styles/styled-components/variables'

import Constants from '../../../../../constants'

interface IFeatures {
  label: string
  title: string
  description: string
  image: string
  labelColor: string
}

const WebAdminFeature: FunctionComponent = () => {
  const { isMobile, isTablet } = useApp()
  const appFeatureDescription: IFeatures[] = [
    {
      label: 'ANALYTICS',
      title: 'Fraud Detection & Analytics',
      description:
        'Detect & flag suspicious submissions simply with your own fraud rules.',
      image: '/assets/images/features/admin-fraud.png',
      labelColor: PURPLE_COLOR,
    },
    {
      label: 'HISTORY',
      title: 'Customer & Team History',
      description:
        'Learn & track everything in real time about your customers & sales teams.',
      image: '/assets/images/features/admin-customer-team-history.png',
      labelColor: MAGENTA_COLOR,
    },
    {
      label: 'AUTO-ASSIGNMENT',
      title: 'AI Auto-assignment',
      description:
        'Our AI will automatically assign your sales agents to their leads in specific areas.',
      image: '/assets/images/features/admin-auto-assignment.png',
      labelColor: ORANGE_COLOR,
    },
    {
      label: 'APPROVAL',
      title: 'Bulk Approval',
      description:
        'Import back all files & update all submission status in the blink of an eye.',
      image: '/assets/images/features/admin-bulk-approval.png',
      labelColor: BLUE_COLOR,
    },
    {
      label: 'SURVEY DATA',
      title: 'Custom Survey',
      description: 'Create a custom survey for every field team.',
      image: '/assets/images/features/admin-survey.png',
      labelColor: RED_COLOR,
    },
    {
      label: 'RAW REPORT',
      title: 'Raw Sales Reports',
      description: 'Receive raw reports of your sales and analyze it your way.',
      image: '/assets/images/features/admin-raw-report.png',
      labelColor: GREEN_COLOR,
    },
    {
      label: 'ATTENDANCE',
      title: 'Attendance Report',
      description: 'Receive daily attendance report from every sales agent.',
      image: '/assets/images/features/admin-attendance.png',
      labelColor: GREEN_COLOR,
    },
  ]

  const renderFeature = () => {
    const content = appFeatureDescription.map(
      (item: IFeatures, idx: number) => {
        const wordingContent = (
          <Space>
            <Text
              color={item.labelColor}
              fontWeight={SEMI_BOLD_FONT_WEIGHT}
              fontSize={isMobile ? SMALL_FONT_SIZE : TEXT_FONT_SIZE}
              letterSpacing={'.2rem'}
            >
              {item.label}
            </Text>
            <Text
              fontSize={
                isMobile ? MOB_HEADING_2_FONT_SIZE : HEADING_1_FONT_SIZE
              }
              fontWeight={SEMI_BOLD_FONT_WEIGHT}
              color={SECONDARY_COLOR}
            >
              {item.title}
            </Text>
            <Text
              fontSize={
                isMobile ? MOB_HEADING_4_FONT_SIZE : HEADING_4_FONT_SIZE
              }
              fontWeight={REGULAR_FONT_WEIGHT}
              color={DARK_GRAY_COLOR}
              maxWidth={'500px'}
              margin={isTablet ? 'auto' : ''}
            >
              {item.description}
            </Text>
          </Space>
        )
        if (idx % 2 === 0) {
          return (
            <Row
              key={idx}
              style={{ marginBottom: isMobile ? '3rem' : '135px' }}
            >
              <Col
                lg={6}
                className={
                  isTablet || isMobile ? 'd-flex justify-content-center' : ''
                }
              >
                <Image
                  src={`${Constants.general.PUBLIC_URL}${item.image}`}
                  width={isTablet ? '440px' : isMobile ? '310px' : '540px'}
                  height={'100%'}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Col>
              <Col
                lg={6}
                className={`d-flex align-items-center mt-3 ${
                  isTablet ? 'text-center' : ''
                }`}
              >
                {wordingContent}
              </Col>
            </Row>
          )
        } else {
          return (
            <Row
              key={idx}
              style={{ marginBottom: isMobile ? '3rem' : '135px' }}
              className={
                isTablet || isMobile ? 'd-flex flex-column-reverse' : ''
              }
            >
              <Col
                lg={6}
                className={`d-flex align-items-center mt-3 ${
                  isTablet ? 'text-center' : ''
                }`}
              >
                {wordingContent}
              </Col>
              <Col
                lg={6}
                className={
                  isTablet || isMobile ? 'd-flex justify-content-center' : ''
                }
              >
                <Image
                  src={`${Constants.general.PUBLIC_URL}${item.image}`}
                  width={isMobile ? '310px' : '540px'}
                  height={'100%'}
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </Col>
            </Row>
          )
        }
      },
    )

    return content
  }

  return (
    <>
      <Section margin={`${isMobile || isTablet ? '4rem' : '5rem '} 0 0 0`}>
        <Container>{renderFeature()}</Container>
      </Section>
    </>
  )
}

export default WebAdminFeature
