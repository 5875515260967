import React, { useEffect } from 'react'
import MainLayout from 'components/layout/main-layout'
import ScrollToTop from 'components/scroll-to-top'
import AboutPage from 'pages/about'
import AdminPanelFeature from 'pages/features/admin-panel'
import MobileAppFeature from 'pages/features/mobile-app'
import HomePage from 'pages/home'

import { Route, Routes, useLocation } from 'react-router-dom'
import PrivacyPolicy from 'pages/privacy-policy'
import SafeData from 'pages/safe-data'
import TermsAndConditions from 'pages/terms-and-conditions'

const App = () => {
  const { pathname, hash, key } = useLocation()
  useEffect(() => {
    // if not a hash link, scroll to top

    if (hash === '') {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView()
        }
      }, 0)
    }
  }, [pathname, hash, key])
  return (
    <MainLayout>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/feature/admin-panel" element={<AdminPanelFeature />} />
        <Route path="/feature/mobile-app" element={<MobileAppFeature />} />

        <Route path="/safe-data" element={<SafeData />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </MainLayout>
  )
}

export default App
