import DemoRegistration from 'pages/home/components/demo-registration'
import React, { FunctionComponent } from 'react'
import WebAdminFeature from './components/features'
import Header from './components/header'

const AdminPanelFeature: FunctionComponent = () => {
  return (
    <>
      <Header />
      <WebAdminFeature />
      <DemoRegistration />
    </>
  )
}

export default AdminPanelFeature
