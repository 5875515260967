import { useApp } from 'contexts/app-context/use-app'
import { FunctionComponent } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BackgroundImage, Section, Text } from 'styles/styled-components'
import {
  BOLD_FONT_WEIGHT,
  DISPLAY_FONT_SIZE,
  MOB_HEADING_1_FONT_SIZE,
  TITLE_FONT_SIZE,
  WHITE_COLOR,
} from 'styles/styled-components/variables'

import Constants from '../../../../../constants'

const Header: FunctionComponent = () => {
  const { isMobile, isTablet } = useApp()
  return (
    <Section margin={`${isMobile ? '80px 0 1rem 0' : '5.5rem 0 3rem 0'} `}>
      <BackgroundImage
        backgroundImgSrc={`${Constants.general.PUBLIC_URL}/assets/images/features/web-admin-bg-1.png`}
        height={isTablet ? '700px' : isMobile ? '400px' : '1400px'}
      >
        <Container>
          <Row>
            <Col>
              <Text
                color={WHITE_COLOR}
                fontSize={
                  isTablet
                    ? TITLE_FONT_SIZE
                    : isMobile
                    ? MOB_HEADING_1_FONT_SIZE
                    : DISPLAY_FONT_SIZE
                }
                fontWeight={BOLD_FONT_WEIGHT}
                textAlign="center"
                margin={`${
                  isTablet
                    ? TITLE_FONT_SIZE
                    : isMobile
                    ? MOB_HEADING_1_FONT_SIZE
                    : '100px'
                } 0 ${
                  isTablet
                    ? TITLE_FONT_SIZE
                    : isMobile
                    ? MOB_HEADING_1_FONT_SIZE
                    : '64px'
                } 0`}
              >
                Onlead Admin Panel
              </Text>
            </Col>
          </Row>
        </Container>
      </BackgroundImage>
    </Section>
  )
}

export default Header
