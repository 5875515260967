import React, { FunctionComponent, useEffect, useState, useRef } from 'react'
import { Wrapper, Image, Text, Space, Flex } from 'styles/styled-components'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { IoIosArrowDown } from 'react-icons/io'
import Button from 'components/controls/button'
import {
  BOLD_FONT_WEIGHT,
  MEDIUM_FONT_WEIGHT,
  MEDIUM_GRAY_COLOR,
  MOB_HEADING_2_FONT_SIZE,
  MOB_TEXT_FONT_SIZE,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TEXT_FONT_SIZE,
  WHITE_COLOR,
} from 'styles/styled-components/variables'
import Constants from '../../../constants'

import '../../../styles/index.scss'
import { useApp } from 'contexts/app-context/use-app'
import { Link, useLocation, useNavigate } from 'react-router-dom'

interface INavigationBar {
  isMobile?: boolean
  isTablet?: boolean
}

const NavigationBar: FunctionComponent<INavigationBar> = () => {
  const navbarRef = useRef(null)
  const navbarTogglerRef = useRef(null)
  const location = useLocation()
  const navigate = useNavigate()

  const { isMobile, isTablet } = useApp()
  const [isStacked, setIsStacked] = useState(false)
  const [isNavbarOpened, setIsNavbarOpened] = useState(false)

  const onClickLoginButton = (): void => {
    window.location.href = 'https://admin.onlead.id/'
  }

  const onClickToggleNav = (): void => {
    setIsNavbarOpened(!isNavbarOpened)
  }

  const onHandleScroll = () => {
    if (window.scrollY > 50) {
      setIsStacked(true)
    } else if (window.scrollY <= 50) {
      setIsStacked(false)
    }
  }

  const renderResponsiveBtn = (type: String) => {
    const onClickBookForDemoBtn = () => {
      onClickToggleNav()
      navigate('#request-demo')
    }

    const buttonContent =
      type === 'book_for_demo' ? (
        <Wrapper margin="2rem 0 0 0">
          <Button
            backgroundColor={SECONDARY_COLOR}
            fontColor={WHITE_COLOR}
            onClick={onClickBookForDemoBtn}
            fullWidth
            fontSize={
              isMobile || isTablet ? MOB_HEADING_2_FONT_SIZE : TEXT_FONT_SIZE
            }
          >
            Book for Demo
          </Button>
        </Wrapper>
      ) : (
        <Button
          backgroundColor={WHITE_COLOR}
          border={`1px solid ${SECONDARY_COLOR}`}
          fontColor={SECONDARY_COLOR}
          onClick={onClickLoginButton}
          fontSize={MOB_TEXT_FONT_SIZE}
          padding={'.5rem 1rem'}
        >
          Login
        </Button>
      )
    const content = isMobile || isTablet ? buttonContent : ''

    return content
  }

  useEffect(() => {
    document.addEventListener('scroll', onHandleScroll)
    return () => {
      document.removeEventListener('scroll', onHandleScroll)
    }
  }, [])

  useEffect(() => {
    if (isMobile || isTablet) {
      setIsStacked(true)
    } else {
      setIsStacked(false)
    }
  }, [isMobile, isTablet])

  const showBookDemoButton =
    !isTablet && !isMobile ? (
      <Wrapper margin="0 0 0 1.5rem">
        <Space flexDirection="row" gap="1rem">
          <Button
            backgroundColor={WHITE_COLOR}
            border={`1px solid ${SECONDARY_COLOR}`}
            fontColor={SECONDARY_COLOR}
            onClick={onClickLoginButton}
          >
            Login
          </Button>
          <Button>
            <Link to={'/#request-demo'}>Book for Demo</Link>
          </Button>
        </Space>
      </Wrapper>
    ) : (
      ''
    )
  return (
    <>
      <Navbar
        fixed="top"
        expand="lg"
        bg="transparent"
        className={`onlead-navbar${
          isStacked || isNavbarOpened ? ' stacked' : ''
        }`}
        ref={navbarRef}
        expanded={isNavbarOpened}
        collapseOnSelect
      >
        <Container>
          <Navbar.Brand href="/">
            <Image
              src={`${Constants.general.PUBLIC_URL}/assets/images/logo/logo-onlead-colored.svg`}
              width={isMobile ? '94px' : '170px'}
              height={isMobile ? '55px' : '30px'}
              display="block"
            />
          </Navbar.Brand>

          <Flex>
            {renderResponsiveBtn('login')}

            <Navbar.Toggle
              aria-controls="onlead-navbar-nav"
              ref={navbarTogglerRef}
              onClick={onClickToggleNav}
            />
          </Flex>
          <Navbar.Collapse id="onlead-navbar-nav">
            <Nav
              className={`ms-auto${
                isTablet ? ' mt-5' : isMobile ? ' mt-2' : ''
              }`}
            >
              <Nav.Link href="/">
                <Text
                  color={
                    location.pathname === '/'
                      ? PRIMARY_COLOR
                      : MEDIUM_GRAY_COLOR
                  }
                  fontWeight={
                    location.pathname === '/'
                      ? BOLD_FONT_WEIGHT
                      : MEDIUM_FONT_WEIGHT
                  }
                  fontSize={
                    isMobile || isTablet
                      ? MOB_HEADING_2_FONT_SIZE
                      : TEXT_FONT_SIZE
                  }
                >
                  Home
                </Text>
              </Nav.Link>
              <Nav.Link href="/about">
                <Text
                  color={
                    location.pathname === '/about'
                      ? PRIMARY_COLOR
                      : MEDIUM_GRAY_COLOR
                  }
                  fontWeight={
                    location.pathname === '/about'
                      ? BOLD_FONT_WEIGHT
                      : MEDIUM_FONT_WEIGHT
                  }
                  fontSize={
                    isMobile || isTablet
                      ? MOB_HEADING_2_FONT_SIZE
                      : TEXT_FONT_SIZE
                  }
                >
                  About Us
                </Text>
              </Nav.Link>
              <NavDropdown
                title={
                  <>
                    <Text
                      display={'inline'}
                      color={
                        location.pathname === '/feature/admin-panel' ||
                        location.pathname === '/feature/mobile-app'
                          ? PRIMARY_COLOR
                          : MEDIUM_GRAY_COLOR
                      }
                      fontWeight={
                        location.pathname === '/feature/admin-panel' ||
                        location.pathname === '/feature/mobile-app'
                          ? BOLD_FONT_WEIGHT
                          : MEDIUM_FONT_WEIGHT
                      }
                      fontSize={
                        isMobile || isTablet
                          ? MOB_HEADING_2_FONT_SIZE
                          : TEXT_FONT_SIZE
                      }
                    >
                      Features
                    </Text>
                    <i className="ms-1">
                      <IoIosArrowDown
                        color={
                          location.pathname === '/feature/admin-panel' ||
                          location.pathname === '/feature/mobile-app'
                            ? PRIMARY_COLOR
                            : MEDIUM_GRAY_COLOR
                        }
                      />
                    </i>
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/feature/admin-panel">
                  <Text
                    color={
                      location.pathname === '/feature/admin-panel'
                        ? PRIMARY_COLOR
                        : MEDIUM_GRAY_COLOR
                    }
                    fontWeight={MEDIUM_FONT_WEIGHT}
                    fontSize={
                      isMobile || isTablet
                        ? MOB_HEADING_2_FONT_SIZE
                        : TEXT_FONT_SIZE
                    }
                  >
                    Admin Panel
                  </Text>
                </NavDropdown.Item>
                <NavDropdown.Item href="/feature/mobile-app">
                  <Text
                    color={
                      location.pathname === '/feature/mobile-app'
                        ? PRIMARY_COLOR
                        : MEDIUM_GRAY_COLOR
                    }
                    fontWeight={MEDIUM_FONT_WEIGHT}
                    fontSize={
                      isMobile || isTablet
                        ? MOB_HEADING_2_FONT_SIZE
                        : TEXT_FONT_SIZE
                    }
                  >
                    Mobile App
                  </Text>
                </NavDropdown.Item>
              </NavDropdown>
              {renderResponsiveBtn('book_for_demo')}
            </Nav>
          </Navbar.Collapse>
          {showBookDemoButton}
        </Container>
      </Navbar>
    </>
  )
}

export default NavigationBar
